import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { apiGetThemeConfig } from 'services/HomeServices';

export const getMenus = createAsyncThunk('themeList/data/getThemeList', async (data) => {
    const response = await apiGetThemeConfig(data);
    const responseData = Array.isArray(response.data) ? response.data : [response.data];
    return responseData;
});

const saveDataToLocalStorage = (data) => {
    localStorage.setItem('themeListData', JSON.stringify(data));
};

const getDataFromLocalStorage = () => {
    const storedData = localStorage.getItem('themeListData');
    return storedData ? JSON.parse(storedData) : undefined;
};

const dataSlice = createSlice({
    name: 'themeList/data',
    initialState: getDataFromLocalStorage() || {
        loading: false,
        themeList: [],
    },
    reducers: {
        updateMenuList: (state, action) => {
            state.themeList = action.payload
            saveDataToLocalStorage(state);
        },
    },
    extraReducers: {
        [getMenus.fulfilled]: (state, action) => {
            state.themeList = action.payload;
            state.loading = false;
            saveDataToLocalStorage(state);
        },
        [getMenus.pending]: (state) => {
            state.loading = true;
        },
    }
})
export const { updateMenuList } = dataSlice.actions

export default dataSlice.reducer
