import { theme } from 'twin.macro'

const twColor = theme`colors`

export const COLOR_1 = twColor.indigo['600'] 
export const COLOR_2 = twColor.blue['500']
export const COLOR_3 = twColor.emerald['500']
export const COLOR_4 = twColor.amber['500']
export const COLOR_5 = twColor.red['500']
export const COLOR_6 = twColor.purple['500']
export const COLOR_7 = twColor.cyan['500']
export const COLOR_8 = twColor.pink['500']
export const COLOR_9 = twColor.teal['500']
export const COLOR_10 = twColor.orange['500']
export const COLOR_11 = twColor.yellow['500']
export const COLOR_12 = twColor.lime['500']
export const COLOR_13 = twColor.gray['500']
export const COLOR_14 = twColor.pink['300']
export const COLOR_15 = twColor.indigo['700']
export const COLOR_16 = twColor.blue['600']
export const COLOR_17 = twColor.emerald['600']
export const COLOR_18 = twColor.amber['600']
export const COLOR_19 = twColor.red['600']
export const COLOR_20 = twColor.purple['600']


export const COLOR_1_LIGHT = twColor.indigo['100'] 
export const COLOR_2_LIGHT = twColor.blue['100']
export const COLOR_3_LIGHT = twColor.emerald['100']
export const COLOR_4_LIGHT = twColor.amber['100']
export const COLOR_5_LIGHT = twColor.red['100']
export const COLOR_6_LIGHT = twColor.purple['100']
export const COLOR_7_LIGHT = twColor.cyan['100']
export const COLOR_8_LIGHT = twColor.pink['100']
export const COLOR_9_LIGHT = twColor.teal['100']
export const COLOR_10_LIGHT = twColor.orange['100']
export const COLOR_11_LIGHT = twColor.yellow['100']
export const COLOR_12_LIGHT = twColor.lime['100']
export const COLOR_13_LIGHT = twColor.gray['100']
export const COLOR_14_LIGHT = twColor.pink['100']
export const COLOR_15_LIGHT = twColor.indigo['200']
export const COLOR_16_LIGHT = twColor.blue['200']
export const COLOR_17_LIGHT = twColor.emerald['200']
export const COLOR_18_LIGHT = twColor.amber['200']
export const COLOR_19_LIGHT = twColor.red['200']
export const COLOR_20_LIGHT = twColor.purple['200']

export const COLORS = [
	COLOR_1,
	COLOR_2,
	COLOR_3,
	COLOR_4,
	COLOR_5,
	COLOR_6,
	COLOR_7,
	COLOR_8,
	COLOR_9,
	COLOR_10,
	COLOR_11,
	COLOR_12,
    COLOR_13,
    COLOR_14,
	COLOR_15,
    COLOR_16,
    COLOR_17,
    COLOR_18,
    COLOR_19,
    COLOR_20
]

export const COLORS_LIGHT = [
	COLOR_1_LIGHT,
	COLOR_2_LIGHT,
	COLOR_3_LIGHT,
	COLOR_4_LIGHT,
	COLOR_5_LIGHT,
	COLOR_6_LIGHT,
	COLOR_7_LIGHT,
	COLOR_8_LIGHT,
	COLOR_9_LIGHT,
	COLOR_10_LIGHT,
	COLOR_11_LIGHT,
	COLOR_12_LIGHT,
	COLOR_13_LIGHT,
	COLOR_14_LIGHT,
	COLOR_15_LIGHT,
    COLOR_16_LIGHT,
    COLOR_17_LIGHT,
    COLOR_18_LIGHT,
    COLOR_19_LIGHT,
    COLOR_20_LIGHT
]